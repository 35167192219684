import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store"; // Adjust this import based on your store setup
import { setChangedParameter, setCurrentCommonParameter } from "../platformParametersSlice";
import { setCurrentCommonParameterWrapper, setParametersConstancyWrapper } from "../parametersConstancyUtils";
import { CONSTANT } from "../../constants/constants";
import { useLocation } from "react-router-dom";
import { CustomDateRange, DateRangeType, Page, tablesRowsAmountPerPageOptions } from "../parametersConstancyTypes";
import { setLastLoadedFiltersType } from "../../core/performanceReports/advancedReport/advancedReportSlice";

const CommonChangedParametersHandler: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { partnersSelected, currentMultiselectGroup }: any = useSelector((state: RootState) => state.role);
  const dateGroupingMode = useSelector(
    (state: RootState) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode ??
      CONSTANT.DATE_GROUP_MODES.DAY
  );
  const customDateRange: CustomDateRange = useSelector(
    (state: RootState) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.customDateRange ?? {
        customDateStart: null,
        customDateEnd: null,
      }
  );
  const dateRange: DateRangeType = useSelector(
    (state: RootState) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateRange ??
      CONSTANT.DATE_RANGE_TYPES.LAST_WEEK
  );
  const tablesRowsAmountPerPage: number = useSelector(
    (state: RootState) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.tablesRowsAmountPerPage ??
      tablesRowsAmountPerPageOptions[0]
  );
  const removeTrailingNumbersFromUrl = (url: string) => {
    return url.replace(/\/(\d+)(?=$|\/)/g, "");
  };

  useEffect(() => {
    const cleanedUpPath = removeTrailingNumbersFromUrl(location.pathname);
    const currentPage: Page =
      Object.values(CONSTANT.PAGES).find((page) => page.path === cleanedUpPath) || CONSTANT.PAGES.OVERVIEW;
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper("commonUserPlatformParameters", "currentPage", currentPage)
      )
    );
    dispatch(setCurrentCommonParameter(setCurrentCommonParameterWrapper("currentPage", currentPage)));
  }, [location, dispatch]);

  useEffect(() => {
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper(
          "commonUserPlatformParameters",
          "currentMultiselectGroupId",
          currentMultiselectGroup?.id || null
        )
      )
    );
  }, [currentMultiselectGroup, dispatch]);

  useEffect(() => {
    const selectedPartnersDummyIds = partnersSelected?.map((partner: any) => partner.dummy_id);
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper(
          "commonUserPlatformParameters",
          "selectedPartnersDummyIds",
          selectedPartnersDummyIds
        )
      )
    );
    dispatch(setLastLoadedFiltersType(""));
  }, [partnersSelected, dispatch]);

  useEffect(() => {
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper("commonUserPlatformParameters", "dateGroupingMode", dateGroupingMode)
      )
    );
    dispatch(setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateGroupingMode", dateGroupingMode)));
  }, [dateGroupingMode, dispatch]);

  useEffect(() => {
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper("commonUserPlatformParameters", "customDateRange", customDateRange)
      )
    );
  }, [customDateRange, dispatch]);

  useEffect(() => {
    dispatch(
      setChangedParameter(setParametersConstancyWrapper("commonUserPlatformParameters", "dateRange", dateRange))
    );
  }, [dateRange, dispatch]);

  useEffect(() => {
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper(
          "commonUserPlatformParameters",
          "tablesRowsAmountPerPage",
          tablesRowsAmountPerPage
        )
      )
    );
  }, [tablesRowsAmountPerPage, dispatch]);

  return null;
};

export default CommonChangedParametersHandler;
