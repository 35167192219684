import { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  sideBarExpandButton,
  sideBarContainer,
  logoContainer,
  menuItem,
  menuItemActive,
  menuChildItem,
} from "./styles";
import logo from "../../assets/images/logo_filled.svg";
import auth from "../../auth/auth-helper";
import Loader from "../Loader";
import { setSideBarIsOpen } from "../../role/roleSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography, Box, Switch } from "@mui/material";
import { setPresentationMode } from "../../parametersConstancy/platformParametersSlice";
import { useGetSidebarData } from "./useGetSidebarData";
import IQLogoSidebarClose from "../IconComponents/IQLogoSidebarClose";
import DoubleArrowLeftIcon from "../IconComponents/DoubleArrowLeftIcon";
import DoubleArrowRightIcon from "../IconComponents/DoubleArrowRightIcon";
import ExpandMoreIcon from "../IconComponents/ExpandMoreIcon";
import { colors } from "../../theme";

function Sidebar({ setFeedbackOpen }) {
  const SidebarData = useGetSidebarData();
  const matches = useMediaQuery("(min-width:1280px)");
  const dispatch = useDispatch();
  const [sideBar, setSideBar] = useState(false);
  const [subMenuOpened, setSubMenuOpened] = useState({});
  const [currentChildren, setCurrentChildren] = useState([]);
  const navigate = useNavigate();
  let location = useLocation();
  const isPresentationModeEnabled = useSelector(
    (state) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.isPresentationModeEnabled
  );
  let jwt = auth.isAuthenticated();

  let anchorRef = useRef(null);

  const showSideBar = () => {
    dispatch(setSideBarIsOpen(!sideBar));
    setSideBar(!sideBar);
  };

  // const isActive = (location, path) => {
  //   if (location.pathname === path) return true;
  // };

  const isActiveLinkStyles = (location, pathArr) => {
    //find alternative solution for nested routes (submenues)
    return pathArr.some((path) => location.pathname.startsWith(path));
  };

  const handleClick = (e, item) => {
    e.stopPropagation();

    if (item.children) {
      handleSubMenuClick(item);
    } else {
      const itemPath = item.path;
      // Check if the path is an external link
      if (itemPath.startsWith("http")) {
        // Open in a new tab for external links
        window.open(itemPath, "_blank");
      } else {
        // Use navigate for internal links
        navigate(itemPath, { state: { previousLocationPathname: location.pathname } });
      }
    }
  };

  const handleSubMenuClick = (item) => {
    setSubMenuOpened((prev) => ({
      ...prev,
      [item.title]: !prev[item.title],
    }));
  };

  // const handlePopoverOpen = (event, children) => {
  //   anchorRef.current = event.currentTarget;
  //   setCurrentChildren(children);
  // };

  // const handlePopoverClose = () => {
  //   anchorRef.current = null;
  //   setCurrentChildren([]);
  // };

  // const open = Boolean(anchorRef.current);

  // if (!matches) {
  //   if (sideBar) {
  //     dispatch(setSideBarIsOpen(false));
  //     setSideBar(false);
  //   }
  //   return (
  //     <Grid container id="sidebar" sx={wrapperMenuItemLeft} direction="row">
  //       {SidebarData.filter(
  //         (el) =>
  //           !el.isDisabled &&
  //           ("shouldBeVisibleFor" in el === false || el.shouldBeVisibleFor.includes(jwt.data?.user?.acctId))
  //       ).map((i, ind) => {
  //         return (
  //           <React.Fragment key={ind + "_" + i.title}>
  //             <Grid
  //               item
  //               sx={
  //                 isActiveLinkStyles(location, i.pathArr)
  //                   ? {
  //                       ...menuItemCollapsed(true),
  //                       ...menuItemActiveCollapsed(true),
  //                     }
  //                   : menuItemCollapsed
  //               }
  //               onClick={(e) => {
  //                 handleClick(e, i.path);
  //               }}
  //               onMouseEnter={(e) => !!i.children && handlePopoverOpen(e, i.children)}
  //             >
  //               <span id={`sidebar-${i.title.replace(/ /g, "-").toLowerCase()}-btn`}>{i.icon}</span>
  //             </Grid>
  //             <Popover
  //               open={open}
  //               anchorEl={anchorRef.current}
  //               onClose={handlePopoverClose}
  //               anchorOrigin={{
  //                 vertical: "top",
  //                 horizontal: "right",
  //               }}
  //               transformOrigin={{
  //                 vertical: "top",
  //                 horizontal: "left",
  //               }}
  //               disableRestoreFocus
  //             >
  //               <Box sx={innerMenuItemLeft} onMouseLeave={handlePopoverClose}>
  //                 {currentChildren &&
  //                   currentChildren.map((child, index) => {
  //                     if (!child.isDisabled) {
  //                       return (
  //                         <Box
  //                           id={`sidebar-${child.title.replace(/ /g, "-").toLowerCase()}-btn`}
  //                           key={index + "_" + child.title}
  //                           sx={
  //                             isActiveLinkStyles(location, child.pathArr)
  //                               ? { color: "#fff" }
  //                               : { color: "rgba(255,255,255,0.5)" }
  //                           }
  //                           onClick={(e) => {
  //                             handleClick(e, child.path);
  //                             handlePopoverClose();
  //                           }}
  //                         >
  //                           <Typography>{child.title}</Typography>
  //                         </Box>
  //                       );
  //                     }
  //                     return null;
  //                   })}
  //               </Box>
  //             </Popover>
  //           </React.Fragment>
  //         );
  //       })}
  //     </Grid>
  //   );
  // }

  if (!auth.isAuthenticated()) return <Loader />;

  return (
    <Box id="sidebar" sx={sideBarContainer(sideBar)} onClick={showSideBar}>
      <Box id="sidebar-container" sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box id="sidebar-logo-container" sx={{ position: "relative", margin: "0 0 24px 0" }}>
          <Box
            sx={{
              ...logoContainer,
              justifyContent: sideBar ? "start" : "center",
              marginTop: !sideBar && "-4px",
            }}
          >
            {/* Logo */}
            {sideBar ? (
              <img src={logo} width={130} height={32} alt="logo" />
            ) : (
              <Box
                sx={{
                  width: "24px",
                  height: "40px",
                }}
              >
                <IQLogoSidebarClose />
              </Box>
            )}
          </Box>
          <Box id="sidebar-expand-btn" sx={sideBarExpandButton(sideBar)} onClick={showSideBar}>
            {sideBar ? <DoubleArrowLeftIcon /> : <DoubleArrowRightIcon />}
          </Box>
        </Box>
        {sideBar ? (
          <Box
            id="sidebar-menu-items-container"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
              flex: "1 0 0",
              alignSelf: "stretch",
            }}
          >
            <Box
              id="sidebar-top-menu"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
                width: "100%",
              }}
            >
              {SidebarData.filter(
                (el) =>
                  !el.isDisabled &&
                  ("shouldBeVisibleFor" in el === false || el.shouldBeVisibleFor.includes(jwt.data?.user?.acctId))
              ).map((item, ind) => {
                return (
                  <Box
                    id={`sidebar-${item.title.replace(/ /g, "-").toLowerCase()}-btn`}
                    key={ind + "_" + item.title}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        cursor: "pointer",
                        "&:hover": {
                          [`#menu-item-${item.title.replace(/ /g, "-").toLowerCase()}-title`]: {
                            backgroundColor: colors.netural400,
                            color: colors.mainWhite,
                            "& svg path": {
                              fill: colors.mainWhite,
                            },
                          },
                          [`#expand-submenu-btn`]: {
                            color: colors.mainWhite,
                            "& svg path": {
                              fill: colors.mainWhite,
                            },
                          },
                        },
                      }}
                      onClick={(e) => {
                        handleClick(e, item);
                      }}
                    >
                      {item.children ? (
                        <Box id="expand-submenu-btn">
                          {subMenuOpened[item.title] ? (
                            <ExpandMoreIcon
                              color={
                                isActiveLinkStyles(location, item.pathArr) ? colors.mainWhite : colors.navy100
                              }
                            />
                          ) : (
                            <Box
                              sx={{
                                transform: "rotate(-90deg)",
                                transition: "transform 0.3s ease-in-out",
                              }}
                            >
                              <ExpandMoreIcon
                                color={
                                  isActiveLinkStyles(location, item.pathArr) ? colors.mainWhite : colors.navy100
                                }
                              />
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <Box sx={{ height: "24px", width: "24px" }} />
                      )}

                      <Box
                        id={`menu-item-${item.title.replace(/ /g, "-").toLowerCase()}-title`}
                        sx={{
                          marginLeft: "8px",
                          ...menuItem,
                          ...(isActiveLinkStyles(location, item.pathArr) && menuItemActive),
                        }}
                      >
                        <Box sx={{ height: "24px" }}>
                          <Typography variant="bodyLargeRegular" fontWeight={500} color="inherit">
                            {item.icon}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="bodyLargeRegular" fontWeight={500} color="inherit">
                            {item.title}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {item.children && subMenuOpened[item.title] && (
                      <Box
                        id="sidebar-submenu"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          alignSelf: "stretch",
                          gap: "2px",
                          paddingLeft: "36px",
                          width: "100%",
                        }}
                      >
                        {item.children.map((child, index) => {
                          if (!child.isDisabled) {
                            return (
                              <Box
                                id={`sidebar-${child.title.replace(/ /g, "-").toLowerCase()}-btn`}
                                key={index + "_" + child.title}
                                sx={{
                                  ...menuItem,
                                  ...menuChildItem,
                                  ...(isActiveLinkStyles(location, child.pathArr) && menuItemActive),
                                  "&:hover": {
                                    backgroundColor: colors.netural400,
                                    color: colors.mainWhite,
                                    svg: {
                                      path: {
                                        fill: (theme) => colors.mainWhite,
                                      },
                                    },
                                  },
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleClick(e, child);
                                }}
                              >
                                <Typography variant="bodyLargeRegular" fontWeight={500} color="inherit">
                                  {child.title}
                                </Typography>
                              </Box>
                            );
                          }
                        })}
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
            {auth.handleBusinessDetailsToggle(jwt) && (
              // auth.checkmodulesVisibility(CONSTANT.MODULES_PAGES.PERFORMANCE_REPORT.modules) &&
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "8px",
                }}
              >
                <Typography variant="unselected" sx={{ color: "white" }}>
                  Hide business details
                </Typography>
                <Switch
                  id="sidebar-hide-business-names-switch"
                  size="small"
                  onClick={(e) => e.stopPropagation()}
                  checked={isPresentationModeEnabled}
                  onChange={(e) => {
                    dispatch(setPresentationMode(!isPresentationModeEnabled));
                  }}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Box
            id="sidebar-top-menu-collapsed"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {SidebarData.filter(
              (el) =>
                !el.isDisabled &&
                ("shouldBeVisibleFor" in el === false || el.shouldBeVisibleFor.includes(jwt.data?.user?.acctId))
            ).map((item, ind) => {
              return (
                <Box
                  key={ind + "_" + item.title}
                  id={`sidebar-${item.title.replace(/ /g, "-").toLowerCase()}-btn`}
                  sx={{
                    ...menuItem,
                    padding: "8px",
                    alignItems: "center",
                    width: "40px",
                    height: "40px",
                    cursor: "pointer",
                    ...(isActiveLinkStyles(location, item.pathArr) && menuItemActive),
                  }}
                  // sx={
                  //   // isActive(location, i.path)
                  //   isActiveLinkStyles(location, item.pathArr)
                  //     ? { ...menuItemCollapsed(), ...menuItemActiveCollapsed() }
                  //     : menuItemCollapsed()
                  // }
                  // disabled={i.isDisabled}
                  // onClick={(e) => {
                  //   handleClick(e, item);
                  // }}
                  onClick={showSideBar}
                >
                  <Box sx={{ height: "24px" }}>
                    <Typography variant="bodyLargeRegular" fontWeight={500} color="inherit">
                      {item.icon}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      {/* <Box
        sx={{
          ...logoContainer,
          position: "fixed",
          bottom: "40px",
          left: sideBar ? "120px" : "18px", // Center horizontally
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "auto",
        }}
      >
        <Box
          id="sidebar-close-need-help-btn"
          onClick={(e) => {
            e.stopPropagation();
            setFeedbackOpen(true);
          }}
        >
          <InfoIcon />
        </Box>
      </Box> */}
    </Box>
  );
}

export default Sidebar;
